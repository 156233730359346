.chat-footer {
  width: 100%;
  padding: 16px;
  border-top: 1px solid gainsboro;
  position: fixed;
  bottom: 0px;
  z-index: 100;
  background-color: var(--white);
}
.voizo-side {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  user-select: none;
}

.user-side {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.chat-element-system {
  padding: 16px;
  border-radius: 12px;
  border: 1px solid var(--primaryLight);
  max-width: 100%;
  min-width: 30%;
  box-shadow: 0px 8px 20px #dcdcdca3;
}

.chat-element-user img {
  width: 70%;
}

.chat-wrapper {
  /* height: calc(100vh - 150px); */
  overflow-y: scroll;
  scroll-padding-block: 100%;
  scroll-behavior: smooth;
  scroll-snap-align: end;
}

.chat-area {
  display: flex;
  flex-direction: column;
}

.recording-card {
  padding: 16px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  background-color: var(--primaryLightest);
}

.recording-card-wrapper {
  width: 100%;
}

.interview-result-card {
  padding: 16px;
  border-radius: 12px;
  border: 1px solid var(--gainsboro);
  width: 70%;
}
.audio-recorder-button-wrapper {
  position: relative;
}

.audio-recorder-button-wrapper .audio-recorder {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: -1;
  opacity: 0.1;
}

.interview-structure-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 85vh;
  padding: 40px;
}

.interview-structure-card {
  border-radius: 10px;
  background-color: var(--white);
  box-shadow: 0px 0px 10px var(--gainsboro);
  padding: 32px 24px;
  max-width: 450px;
  width: 100%;
}

.chat-modal-wrapper {
  max-height: 400px;
  overflow-y: scroll;
}

.hide-webcam {
  position: absolute;
  z-index: -1;
  visibility: hidden;
}

.answer {
  justify-content: start !important;
}

.response {
  width: 150px;
}
.progress {
  top: -17px;
  z-index: -1;
  width: 100%;
}

.circle {
  position: relative;
  background-color: var(--lightestGray);
  height: 20px;
  width: 20px;
  border-radius: 50%;
  transition: background-color 2s;
  display: flex;
  justify-content: center;
}

.completed {
  background-color: var(--primary);
}

.attribute-name {
  top: 30px;
  color: var(--textGray);
}

.increase {
  margin-left: 400px;
  margin-top: 50px;
  position: absolute;
}

.decrease {
  margin-top: 50px;
  position: absolute;
}

.custom-progress-bar {
  width: 80vw;
  margin: 40px auto 100px auto;
}

.progress-bar-container {
  width: 100%;
  background-color: var(--gainsboro);
  border-radius: 4px;
  height: 44px;
  display: flex;
  text-align: center;
}

.progress-bar {
  height: 44px;
  background-color: var(--primary);
  border-radius: 4px;
  transition: width 0.4s ease;
}

.ans {
  width: 100%;
  margin: auto;
}

.slideAnimation {
  animation: fadeInLeft 2s;
  animation-timing-function: ease-in;
}

@keyframes fadeInLeft {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  50% {
    opacity: 0;
    transform: translateX(-100vw);
  }
  51% {
    opacity: 0;
    transform: translateX(100vw);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.active-step {
  color: var(--primary);
  font-weight: bold;
  font-size: 19px;
}

.ringer-container {
  background-color: black;
  height: 100vh;
  width: 100%;
  color: white;
}

.phn-number {
  height: 30vh;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.circle-ringing {
  height: 70px;
  width: 70px;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Dialing Screen  */
.dialing-container {
  height: 100vh;
  width: 100%;
  margin: auto;
}

.view-area {
  width: 100%;
  height: 25%;
  position: relative;
  padding: 2%;
  overflow: hidden;
}

.dialled-number {
  position: absolute;
  width: fit-content;
  left: 50%;
  transform: translateX(-50%);
  bottom: 2%;
}

.dial-area {
  width: 100%;
  height: 51%;
  padding-top: 6%;
}

.call-controls {
  width: 100%;
  height: 14%;
}

.footer {
  width: 100%;
  height: 10%;
  display: flex;
  border-top: 0.5px solid gray;
}

.dial-circle {
  background-color: rgb(215, 213, 213);
  height: 9vh;
  width: 9vh;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.dial-circle h3 {
  margin: 0;
}

.dial-circle p {
  margin: 0;
  font-size: 0.8rem;
}

.dialing-circle {
  background-color: green;
  height: 10vh;
  width: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.backspace-icon {
  height: 10vh;
  width: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-btn {
  padding: 0;
  height: 100%;
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 5px;
  color: rgb(170, 169, 169);
}

.footer-btn p {
  font-size: 14px;
}

.circle-data {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.keypad-icon {
  width: 35%;
}
