.modal-content {
  border-radius: 8px;
  position: relative;
}

.modal-icon {
  position: absolute;
  right: 16px;
  top: 16px;
  cursor: pointer;
}

.modal-wrapper {
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  padding: 24px;
  height: 100%;
  background: #00000085;
  z-index: 100;
}

.modal-dialog {
  margin-top: 17vh;
  background: var(--white);
  border-radius: 12px;
  color: var(--black);
}

.modal-content {
  background-color: unset;
}

.modal-bg-light {
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-bg-dark {
  background-color: rgba(0, 0, 0, 0.8);
}
