.interview-card {
  border-radius: 10px;
  border: 1px solid var(--gainsboro);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.gainsboro {
  background-color: var(--gainsboro);
}

.logo {
  width: auto;
  height: 32px;
}
