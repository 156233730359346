.btn {
  min-height: 44px;
  font-weight: 500;
  border-radius: 8px;
  padding: 0.5rem 1rem;
}

.btn-primary {
  background-color: var(--primary);
  color: var(--white);
  transition: 1s;
  border-color: var(--primary);
}

.btn-success {
  background-color: var(--green);
  color: var(--white);
  transition: 1s;
  border-color: var(--green);
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: var(--textGray);
  border-color: var(--textGray);
}

.btn-light {
  border: 1px solid var(--gainsboro);
}

.btn-link {
  text-decoration: none;
  color: var(--primary);
}

.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover {
  background-color: var(--primary);
  border-color: var(--primary);
  color: var(--white);
  transform: scale(1.03);
  box-shadow: none;
}

.option-btn {
  background-color: var(--gray);
  border-radius: 8px;
  border: 1px solid var(--grayLight);
  padding: 1rem;
  margin-bottom: 12px;
}

.option-btn h3 {
  color: var(--white);
}

.option-btn:hover {
  background-color: var(--white);
}

.option-btn:hover h3 {
  color: var(--black);
}
