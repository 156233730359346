.primary-input {
  padding: 12px;
  border-radius: 8px;
}

.right-padding {
  padding-right: 40px;
}

.primary-input:focus {
  border: 1px solid var(--primary);
  box-shadow: none;
}

.suffix-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0px, -50%);
  color: var(--textGray);
}
